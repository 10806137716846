<template>
  <div class="q-pa-md">
    <q-card>
      <q-card-section class="row border-bottom items-center full-width q-py-xs q-pl-none">
        <q-legend
            :label="$t('Carton Forwarding')"
            text-class="text-h6"
        />

        <search
            dense
            autoset
            is-expandable
            @submit="handleSearch"
        />

        <q-space />

        <q-btn
            :color="serverParams.filter && serverParams.filter.length > 0 ? 'light-blue-9' : 'dark'"
            text-color="white"
            size="sm"
            class="q-mr-sm"
            :label="filterBtnText"
            no-caps
            unelevated
            @click="openCloseFilters"
        />

        <q-btn
            color="dark"
            text-color="white"
            :label="$t('Refresh')"
            size="sm"
            class="q-mr-sm"
            no-caps
            unelevated
            @click="refreshItems"
        />

        <q-btn
            color="light-blue-9"
            text-color="white"
            class="q-ml-sm"
            icon="add"
            size="sm"
            no-caps
            unelevated
            @click="create"
        />
      </q-card-section>


      <q-card-section class="q-ma-none q-pa-none">
        <filters-collapse
            :is-open="isOpenFilters"
            :options="{
              defaultFilter: serverParams.filter,
              fields: activatedFields,
              values: {
                'states': states,
              }
            }"
            @submit="handleFiltersSubmit"
            @close="openCloseFilters"
            @update="handleFilterUpdate"
        />
      </q-card-section>

      <q-card-section
          class="row q-pa-none"
      >
        <status-filter
            class="hide-on-mobile"
            :outside-selected-status="selectedStatusFilter"
            :statuses="statuses"
            @on-change="onStatusFilter"
        />

        <div class="col">
          <q-table
              style="height: calc(100vh - 130px);"
              class="sticky-header-table"
              row-key="id"
              :rows-per-page-label="$t('Rows per page')"
              :rows="movements"
              :columns="columns"
              v-model:pagination="pagination"
              :loading="movementsLoading"
              :filter="filter"
              :rows-per-page-options="[25, 50, 100, 150, 200, 250]"
              virtual-scroll
              binary-state-sort
              flat
              @request="onRequest"
          >

            <template v-slot:loading>
              <q-inner-loading
                  showing
                  color="primary"
              />
            </template>

            <template v-slot:body="props">
              <delivery-services-movements-row
                  :data="props"
                  @dblclick="openCartonForwarding"
              />
            </template>
          </q-table>
        </div>
      </q-card-section>
    </q-card>

    <carton-forwarding-modal ref="cartonForwardingModal"/>
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapActions, mapMutations } from 'vuex'

// Mixins
import TableMixin from '../../components/global/TableMixin'

// Components
import FiltersCollapse from '../../components/filters/FilterCollapse'
import Search from '../../components/search/Search'
import DeliveryServicesMovementsRow from '../../components/delivery-services-movements/DeliveryServicesMovementsRow'
import StatusFilter from '../../components/filters/StatusFilter'
import CartonForwardingModal from '../../components/modals/CartonForwardingModal'

// Utils
import { buildQuery } from '../../utils/query-utils'

export default {
  name: 'CartonForwarding',
  components: {
    FiltersCollapse,
    Search,
    DeliveryServicesMovementsRow,
    StatusFilter,
    CartonForwardingModal
  },
  mixins: [
    TableMixin
  ],
  data () {
    return {
      filter: '',
      isOpenFilters:null,
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      statuses: [
        {
          id: 1,
          group: 'Default',
          color: 'grey-5',
          buttons: [
            {
              id: 'new',
              title: 'New',
              color: 'info',
              editableField: false,
              transitions: []
            },
            {
              id: 'cancelled',
              title: 'Cancelled',
              color: 'negative text-white',
              editableField: false,
              transitions: []
            },
            {
              id: 'confirmed',
              title: 'Confirmed',
              color: 'yellow',
              editableField: false,
              transitions: []
            },
            {
              id: 'complete',
              title: 'Complete',
              color: 'success',
              editableField: false,
              transitions: []
            },
            {
              id: 'deleted',
              title: 'Deleted',
              color: 'danger',
              editableField: false,
              transitions: []
            },
            {
              id: 'locked',
              title: 'Locked',
              color: 'warning',
              editableField: false,
              transitions: []
            },
            {
              id: 'checking',
              title: 'Checking',
              color: 'warning',
              editableField: false,
              transitions: []
            }
          ]
        },
      ],
      states: [
        { id: 'new', title: this.$t('New')},
        { id: 'confirmed', title: this.$t('Confirmed')},
        { id: 'complete', title: this.$t('Complete')},
        { id: 'cancelled', title: this.$t('Cancelled')},
        { id: 'deleted', title: this.$t('Deleted')},
        { id: 'locked', title: this.$t('Locked')},
        { id: 'checking' , title: this.$t('Checking')}
      ],
      columns: [
        {
          label: '#',
          name: 'number',
          align: 'left'
        },
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Quantity of Shipments'),
          name: 'deliveryRequest',
          align: 'left'
        },
        {
          label: this.$t('Sender'),
          name: 'sender',
          align: 'left'
        },
        {
          label: this.$t('State'),
          name: 'state',
          align: 'left'
        }
      ],
      activatedFields: [
        'id',
        'state',
          'created.from',
          'created.to',
          'updated.from',
          'updated.to',
          'sender'
      ]
    }
  },
  mounted() {
    this.loadDefaultItems()
  },
  computed: {
    ...mapGetters([
      'totalMovementsNumber',
      'movementsLoading',
      'movements'
    ]),
    filterBtnText () {
      return this.serverParams.filter && this.serverParams.filter.length > 0
          ? this.$t('Filtered: ') + this.totalMovementsNumber
          : this.$t('Filter')
    },
    selectedStatusFilter () {
      const status = (this.serverParams.filter || []).find(filter => {
        return filter.field === 'state' && !filter.alias
      })

      if (status && status.values) {
        return status.values
      }

      return status
          ? [status.value]
          : []
    },
  },
  methods: {
    ...mapActions([
      'loadMovements'
    ]),
    handleSearch (search) {
      return this.onRequest({ pagination: { page: 1, search } })
    },
    openCloseFilters () {
      this.isOpenFilters = !this.isOpenFilters
    },
    refreshItems () {
      return this.onRequest({
        pagination: {
          forceReload: true
        }
      })
    },
    handleFiltersSubmit (filter) {
      this.isOpenFilters = false
      return this.onRequest({ pagination: { filter, page: 1 } })
    },
    handleFilterUpdate ({ value, oldValue }) {
      if (oldValue['state==i'].length <= 0 && value['state==i'].length > 0 && !value.items.from) {
        value.items.from = 1
      }
    },
    onRequest(data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      if (query.search && query.search[query.search.length - 1] !== '*' && query.search[query.search.length - 2] !== ':' && !query.search.includes('%')) {
        query.search += query.search[query.search.length - 1] === ':'
            ? '*'
            : ':*'
      }

      this.updateParams(query)

      return this.loadMovements(this.serverParams)
          .then(({ page, totalItems }) => {
            this.pagination = {
              ...this.pagination,
              page,
              rowsNumber: totalItems
            }

          })
          .catch(err => {
            if (err.message === 'No warehouses found') {
              this.isNeededWarehouse = true
            }
          })
    },
    loadDefaultItems () {
      return this.onRequest({ pagination: {} })
    },
    onStatusFilter (values) {
      const query = {
        per_page: this.serverParams.perPage || 25,
        page: 1,
        'order-by': [
          {
            type: 'field',
            field: 'id',
            direction: 'desc'
          }
        ],
        filter: [
          ...(this.serverParams.filter || [])
        ]
      }

      query.filter = query.filter.filter(val => {
        return val.type !== 'in' && val.field !== 'state'
      })

      if (values.length > 0) {
        query.filter.push({ type: 'in', field: 'state', values })
      }

      this.updateParams(query)
      this.loadMovements(query)
    },
    create () {
      this.$refs.cartonForwardingModal.open()
    },
    openCartonForwarding (item) {
      this.$router.push(`/inbound/carton-forwarding/entity/${item.id}`)
    }
  }
}
</script>